<template>
    <!--登录 -->
    <div class="zl-login">
        <div class="bg">
            <!-- <img src="https://kl-sign.oss-cn-beijing.aliyuncs.com/yunqian/login-bg.png" > -->
        </div>
        <div class="inp-box">
            <!-- <div class="phone-item">
				<span>请输入手机号</span>
				<div class="phone">
					<input type="number" maxlength="11" v-model="phone" >
				</div>
			</div> -->

            <div class="item">
                <div class="inp">
                    <input type="text" maxlength="11" placeholder="请输入手机号" v-model="phone" />
                </div>
            </div>
            <div class="item">
                <div class="inp code">
                    <input type="text" placeholder="请输入验证码" maxlength="6" v-model="authCode" />
                    <span @click="getVerifyCodeDeb" v-show="codeShow">
                        获取验证码
                    </span>
                    <span v-show="!codeShow">
                        {{ timeDown }}s
                    </span>
                </div>
            </div>
            <div class="switch-code" @click="getVoiceCodeDeb" v-if="switchShow">
                <img src="@/assets/yq/yy-code.png">
                <span>{{switchText}}</span>
            </div>
            <div class="item" v-show="false">
                <div class="inp">
                    <input type="text" placeholder="邀请码" disabled v-model="invitationCode" maxlength="32" />
                </div>
            </div>
            <div class="btn" @click="submitLogin">
                <button>登录</button>
            </div>
        </div>
        <div class="triangle">
            <img src="@/assets/yq/bottom-triangle.png" />
        </div>
        <auth-tips :isShow="authShow" @close="closeAuthTip" :wxUrl="wxUrl" />
    </div>
</template>

<script>
import authTips from "@/components/login/authTips.vue";
import { setInvitCode, getInvitCode, setAuthInfo } from "@/utils/auth";
import { getInvitationCode, sendVoiceCode, getVerifyCode, zlLogin } from "@/api/user";
import { isWeixin } from "@/utils/index";
import { throttleFn } from '@/utils/index';

export default {
    components: {
        authTips
    },
    data() {
        return {
            phone: "",
            authCode: "",
            hiddenPhone: "",
            invitationCode: "",
            invitationCodeWb: "", //外部进来获取的code
            timeDown: 60,
            codeShow: true,
            voiceDown: 60,
            voiceCodeShow: true,
            authShow: false,
            timer: null,
            voiceTimer: null,
            getCode: false,
            wxUrl: "",
            btnFlag: true,
            switchCode: "message",
            switchText: "收不到验证码？试试语音验证码",
            switchShow: false,
        };
    },
    methods: {
        handleSwitch() {
            if (this.switchCode == "voice") {
                this.switchCode = "message";
                this.switchText = "语音验证码";
            } else {
                this.switchCode = "voice";
                this.switchText = "短信验证码";
            }
            this.codeShow = true;
            this.timeDown = 60;
            clearInterval(this.timer);
        },
        closeAuthTip() {
            this.authShow = false;
        },
        //检测手机号是否正确
        checkPhoneRes() {
            let bool = true;
            let reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
            if (!reg.test(this.phone)) {
                this.$toast({
                    message: "手机号码格式有误",
                });
                bool = false;
            }
            return bool;
        },
        //获取语音验证码
        getVoiceCodeDeb:throttleFn(function(){
            this.getVoiceCode()
        },1000),
        getVoiceCode() {
            if(!this.checkPhoneRes()) return;
            //验证码倒计时
            if (this.voiceCodeShow) {
                this.voiceCodeShow = false;
                sendVoiceCode({ mobile: this.phone }).then((codeRes) => {
                    if (codeRes.status) {
                        this.getCode = true;
                    } else {
                        this.$toast({
                            message: "获取语音验证码失败"
                        })
                    }
                }).catch(err => {
                    this.$toast({
                        message: "获取语音验证码失败"
                    })
                    this.codeShow = true;
                })
            }
        },
        //获取短信验证码
        getVerifyCodeDeb:throttleFn(function(){
            this.getVerifyCode()
        },1000),
        getVerifyCode() {
            if(!this.checkPhoneRes()) return;
            //验证码倒计时
            if (this.codeShow) {
                this.codeShow = false;
                getVerifyCode({ mobile: this.phone }).then((codeRes) => {
                    if (codeRes.code === 0) {
                        this.codeDownTimer();
                        this.getCode = true;
                    } else {
                        this.codeShow = true;
                        this.$toast({
                            message: codeRes.message
                        })
                    }
                }).catch(err => {
                    this.$toast({
                        message: "获取验证码失败"
                    })
                    this.codeShow = true;
                })
            }
        },
        //登录
        submitLogin() {
            var data = {
                mobile: this.phone,
                checkCode: this.authCode,
            };
            if (!data.mobile) {
                this.$toast({
                    message: "手机号码不能为空",
                });
                return false;
            }
            if (!data.checkCode) {
                this.$toast({
                    message: "验证码不能为空",
                });
                return false;
            }
            if (!this.btnFlag) {
                return;
            }
            this.btnFlag = false;
            zlLogin(data).then((res) => {
                this.btnFlag = true;
                // console.log("stringify", JSON.stringify(res.data));
                let { code, message } = res;
                if (code !== 0) {
                    this.$toast({
                        message,
                    });
                    return;
                }
                
                let { authInfo, accessToken, wxAuthed } = res.data;
                //设置到本地缓存
                this.$store.dispatch("user/setTokenAction", accessToken);
                this.$store.dispatch("user/setTokenResAction", JSON.stringify(res.data));
                this.$store.dispatch("user/setLoginPhoneAction", this.phone);
                this.restData();

                //判断灵活协议
                // this.jumpAgreements(false)
                this.$store.dispatch("user/checkNimbleAgreements").then(res => {
                    this.jumpAgreements(res)
                })
                
            }).catch((res) => {
                console.log(res);
            });
        },
        jumpAgreements(bool) {
            let path = bool ? '/index' : '/agileAgreement'
            if(path === '/index'){
                this.$router.replace({ path })
                return;
            }
            this.$router.push({ path })
            return;
        },
        restData() {
            this.phone = "";
            this.authCode = "";
            //邀请码不是外部进来的话要清空
            if (!this.invitationCodeWb) {
                this.invitationCode = "";
            }
        },
        codeDownTimer() {
            this.timeDown = 60;
            this.timer = setInterval(() => {
                this.timeDown--;
                if (this.timeDown <= 0) {
                    this.codeShow = true;
                    this.timeDown = 60;
                    this.voiceCodeShow = true;
                    clearInterval(this.timer);
                }
                // if(this.timeDown == 55){
                this.switchShow = true;
                // }
            }, 1000);
        },
        cancelTimer() {
            this.codeShow = true;
            this.timeDown = 60;
            clearInterval(this.timer);
        },
    },
    created() {
        // var code = getInvitCode();
        // if (code) {
        //     this.invitationCode = code;
        //     this.invitationCodeWb = code;
        // } else {
        //     var code = options.scene;
        //     if (code) {
        //         setInvitCode(decodeURIComponent(code));
        //         this.invitationCode = code;
        //         this.invitationCodeWb = code;
        //     }
        // }
        // this.invitationCode = "1690";
        // this.invitationCodeWb = "1690";
        if (+sessionStorage.getItem('agreeMentBack') === 1) {
            this.authShow = true;
        }

        //每次到登录页都清除缓存
        this.$store.dispatch('user/loginOut');

    },
};
</script>

<style lang="scss" scoped>
div {
    box-sizing: border-box;
}
.zl-login {
    background: linear-gradient(to right, #2c3c82, #4657a3);
    /* padding-top: 328px; */
}
.zl-login .bg {
    height: 100vh;
    width: 100%;
    // background: url("https://kl-sign.oss-cn-beijing.aliyuncs.com/yunqian/login-bg.png") center center / 100% 100% no-repeat;
    background: url("~@/assets/yq/login-bg.png") center center / 100% 100%
        no-repeat;

    /* background-image: url("https://kl-sign.oss-cn-beijing.aliyuncs.com/yunqian/login-bg.png");
    background-size: center;
    background-position:center; */
}
.zl-login .bg img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}
.zl-login .inp-box {
    position: absolute;
    top: 52%;
    left: 31.5px;
    z-index: 10;
    width: 314px;
}
.zl-login .inp-box .item,
.phone-item {
    height: 64.5px;
    border-bottom: 1px solid #5765a5;
}
.zl-login .inp-box input {
    color: #f8f8fd;
    width: 180px;
}
.zl-login .inp-box .item {
    padding-top: 29px;
    position: relative;
}
.zl-login .inp-box .item .inp {
    height: 16px;
}
.zl-login .inp-box .inp.code {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.zl-login .inp-box .inp.code span {
    position: absolute;
    right: 0;
    font-size: 14px;
    color: #828ec5;
    padding-left: 14px;
    padding-right: 8px;
    cursor: pointer;
    color: #bfcbfb;
    border-left: 1px solid #5765a5;
    word-break: keep-all; /* 不换行 */
    white-space: nowrap; /* 不换行 */
}
.zl-login .inp-box .phone-item {
    padding-top: 3px;
}
.zl-login .inp-box .phone-item span {
    font-size: 13px;
    color: #8492d1;
}
.zl-login .inp-box .phone-item .phone {
    margin-top: 6px;
}
.zl-login .inp-box .btn {
    width: 314px;
    margin: 10% auto;
    margin-bottom: 0;
}
.zl-login .inp-box .btn button {
    width: 100%;
    height: 48px;
    background: linear-gradient(to right, #4971d7, #56ace9);
    color: #fff;
    text-align: center;
    line-height: 48px;
    border-radius: 24px;
    border: 1px solid #4971d7;
}
.zl-login .inp-box .btn button:active {
    opacity: 0.8;
}

.zl-login .triangle {
    position: absolute;
    bottom: 0;
    left: 0;
}
.zl-login .triangle img {
    width: 187.5px;
    height: 132.5px;
}
input::-webkit-input-placeholder {
    color: #8492d1;
}
.zl-login .switch-code {
    margin-top: 18px;
    justify-content: left;
    font-size: 14px;
    color: #bbc7f9;
    display: flex;
    align-items: center;
}
.zl-login .switch-code > img {
    width: 15px;
    height: 15px;
}
.zl-login .switch-code > span {
    margin-left: 5px;
}
@media screen and (max-height: 669px) {
    .zl-login .inp-box {
        top: 50%;
    }
}
</style>