<template>
    <div class="auth-tip"
        v-show="isShow"
    >
        <div class="modal">
            <div>
                <div class="close" @click.stop="handleClose">
                    <img src="@/assets/yq/auth-close.png"/>
                </div>
                <div class="title">
                    实名信息授权提示
                </div>
                <div class="desc">
                    基于统一的账号服务体系，实名认证信息可同步在"卓猎云签"内使用
                </div>
                <div class="agree">
                    确认代表同意<span @click="goAgreeMent">《卓猎云签用户条款》</span>
                </div>
                <div class="btn color-fff" @click.stop="goThreeAuth">
                    同意授权
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import { isWeixin } from "@/utils/index";
    export default {
        props: {
            isShow: {
                type: Boolean,
            },
            wxUrl: {
                type: String,
            },
        },
        methods: {
            handleClose() {
                this.$emit("close");
            },
            goThreeAuth() {
                sessionStorage.removeItem("agreeMentBack");
                if( !isWeixin() ){
                    this.$router.replace({
                        path: "/threeAuth",
                        query:{
                            time:new Date().getTime()
                        }
                    });
                    return;
                }
                if( this.wxUrl ){
                    window.location.href = this.wxUrl;
                }else{
                    this.$router.replace({
                        path: "/threeAuth",
                        query:{
                            time:new Date().getTime()
                        }
                    });
                }
                
            },
            goAgreeMent(){
                sessionStorage.setItem("agreeMentBack",1);
                this.$router.push({
                    path: "/agreement",
                    query:{
                        time:new Date().getTime()
                    }
                });
            }
        },
    }
</script>

<style scoped>
div{
    box-sizing: border-box;
}
.auth-tip{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgb(0, 0, 0, 0.5);
    z-index: 20;
}
.auth-tip .modal{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 302px;
    height: 256px;
    background: #fff;
    z-index: 50;
    border-radius: 6px;
}
.auth-tip .modal{
    border-radius: 6px;
    padding: 13px 10px 0 24px;
}
.auth-tip .title{
    margin-top: 5px;
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: 800;
    color: #2E2E2F;
}
.auth-tip .desc{
    font-size: 14px;
    font-weight: 500;
    color: #818182;
    margin-top:15px;
    line-height: 22px;
    width: 251px;
}
.auth-tip .agree{
    font-size: 14px;
    margin-top: 13px;
    display: flex;
    align-items: center;
}
.auth-tip .agree span{
    color: #4B5EB4;
}
.auth-tip .btn{
    width: 252.5px;
    height: 44px;
    background: linear-gradient(-90deg, #4B62BD, #5267BF);
    border-radius: 44px;
    font-size: 15px;
    text-align: center;
    line-height: 44px;
    margin-top: 36px;
    color: #fff;
}
.auth-tip .btn:active{
    opacity: 0.8;
}
.auth-tip .close{
    text-align: right;
    height: 20px;
}
.auth-tip .close img{
    width: 12px;
    height: 12px;
}
</style>
